// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  timeout: 30000,
  // authentication
  authUrl: 'https://auth.aigenomicresources.com/api/v1/',
  apiUrl: 'https://api.aigenomicresources.com/api/omim/',
  // API
  apiFdaUrl: 'https://api.aigenomicresources.com/api/fda/',
  apiBaseUrl: 'https://api.aigenomicresources.com/api/',
  // OMIM
  apiOmin: 'https://api.aigenomicresources.com/api/omim/ClinVar',
  apiOminSearch: 'https://api.aigenomicresources.com/api/omim/ClinVarByCriteria',
  apiOminDetails: 'https://api.aigenomicresources.com/api/omim/ClinVarDetails',
  // SNP
  apiSnp: 'https://api.aigenomicresources.com/api/omim/Snp',
  apiSnpSearch: 'https://api.aigenomicresources.com/api/omim/SnpByCriteria',
  apiSnpDetails: 'https://api.aigenomicresources.com/api/omim/SnpById',
  apiSnpCreateExcel: 'https://api.aigenomicresources.com/api/omim/SnpCreateExcel',
  apiSnpGetExcel: 'https://api.aigenomicresources.com/api/omim/SnpGetExcel',
  apiSnpGetGroupExcel: 'https://api.aigenomicresources.com/api/omim/SnpGetGroupExcel',
  apiSnpGraphService: "https://api.aigenomicresources.com/api/omim/snpgraphservice",
  // ICD
  apiIcd: 'https://api.aigenomicresources.com/api/omim/ICD/',
  apiIcdDetails: 'https://api.aigenomicresources.com/api/omim/ICDDetails/',
  apiIcdSearch: 'https://api.aigenomicresources.com/api/omim/IcdByCriteria/'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
