<mat-card class="mat-elevation-z8">
  <mat-card-content>
    <canvas
        baseChart
        [data]="pieChartData"
        [labels]="pieChartLabels"
        [chartType]="pieChartType"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [colors]="pieColors"
        [legend]="pieChartLegend">
    </canvas>
  </mat-card-content>

  <div  fxLayout="row wrap"
        fxLayoutAlign="space-around stretch"
        fxLayout.lt-sm="column">
        <mat-card-footer *ngFor="let item of pieChartfooter">
          <div class="mb10" fxFlex="20" fxFlex.lt-lg="49">
            <b>{{item.des + ':'}}</b> {{item.value}}
          </div>
        </mat-card-footer>
  </div>

</mat-card>
