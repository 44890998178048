import { Injectable } from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})

export class LoadingNumberService {

  constructor(private spinner: NgxSpinnerService ) { }

  loadingShow(): void {
    const option = (Math.floor(Math.random() * 3) + 1).toString();
    //console.log(option);
    localStorage.setItem('loadingOption', option);
    this.spinner.show();
  }

  loadingHide(): void {
    this.spinner.hide()
  }

}
