import { AuthenticationService } from './../../auth/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {apisTestService} from '../../services/apisTestService';
import { rejects } from 'assert';
import { LoadingNumberService } from '../../services/loading-number.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  userLogged;
  selectedLanguage = 'en';
  isFront: boolean;
  isAdmin: boolean;
  
  constructor(
    private ApisTestService: apisTestService,
    private loadingService: LoadingNumberService,
    private translateService: TranslateService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.translateService.setDefaultLang(this.selectedLanguage);
    this.translateService.use(this.selectedLanguage);
  }

  ngOnInit(): void {
    if (this.router.url === '/') {
      this.isFront = true;
    } else {
      this.isFront = false;
    }

    this.userLogged = this.authenticationService.getUser();
    this.isAdmin = (this.authenticationService.getUser().username && this.authenticationService.getUser().username == 'aigr'); // need mod
    //this.isAdmin = true;
    
  }

  Logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('id');
    this.userLogged = null;
    this.router.navigateByUrl('/access/login');
  }

  isConnected(): boolean {
    return navigator.onLine;
  }

  testConnection() {
    if (navigator.onLine){
      alert('Connected');
    }else{
      alert('Offline');
    }
  }

  testAPI() {

    fetch(this.ApisTestService.getApiUrl()).then(function(response){

      if (!response.ok){
        alert('Data API Offline');
      }else{
        alert('Data API Online');
        
      }
    }).catch(function(error){

      alert('Error accessing API Services');
      rejects(error);
    });


  }

  testAuth() {
 
    fetch(this.ApisTestService.getAuthUrl()).then(function(response){

      if (!response.ok){
        alert('Access API Offline');
      }else{
        alert('Access API Online');
      }
    }).catch(function(error){

      alert('Error accessing Auth Services');
      rejects(error);
    });
  }

  // async fetchWithTimeout(resource) {
  //   const options = { timeout: 5000 };
    
  //   const controller = new AbortController();
  //   const id = setTimeout(() => controller.abort(), environment.timeout);
  //   const response = await fetch(resource, {
  //     ...options,
  //     signal: controller.signal  
  //   });

  //  clearTimeout(id);

  //   return response;
  // }
  

  // selectLanguage(lang: string): void {
  //   localStorage.setItem('selectedLanguage', lang);
  //   this.translateService.use(lang);
  //   this.selectedLanguage = lang;
  // }
}
