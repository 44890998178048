import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { Footer } from '@app/models/request';
import { ChartOptions, ChartType } from 'chart.js';
import { Colors, Label } from 'ng2-charts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit{
  @Input() pieChartLabels: Label[] ;
  @Input() pieColors: Colors[] =  [{backgroundColor: []}];
  @Input() pieChartData: number[];
  @Input() pieChartfooter: Footer[];

  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems, data) {
          return data.datasets[0].data[tooltipItems.index] + ' %';
        }
      }
    },
  };

  pieChartType: ChartType = 'pie';

  pieChartLegend = true;

  pieChartPlugins = [];

  /* pieChartColors = [
    {
      backgroundColor: this.pieColors, //['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ]; */

  constructor() {}

  ngOnInit(): void {
    //console.log(this.pieColors);
  }

}
