import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./forntend-main/forntend-main.module').then(
        (m) => m.ForntendMainModule
      ),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'access',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'faqs',
    loadChildren: () => import('./faqs/faqs.module').then((m) => m.FaqsModule),
  },
  {
    path: 'icd',
    loadChildren: () =>
      import('./icd/icd.module').then((m) => m.IcdModule),
  },
  {
    path: 'icd-detail',
    loadChildren: () =>
      import('./icd-detail/icd-detail.module').then((m) => m.IcdDetailModule),
  },
  {
    path: 'icd-branch',
    loadChildren: () =>
      import('./icd-branch/icd-branch.module').then((m) => m.IcdBranchModule),
  },
  {
    path: 'icd-search',
    loadChildren: () =>
      import('./icd-search/icd-search.module').then((m) => m.IcdSearchModule),
  },
  {
    path: 'consult',
    loadChildren: () =>
      import('./consult/consult.module').then((m) => m.ConsultModule),
  },
  {
    path: 'our-history',
    loadChildren: () =>
      import('./our-history/our-history.module').then(
        (m) => m.OurHistoryModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'term-conditions',
    loadChildren: () =>
      import('./term-conditions/term-conditions.module').then(
        (m) => m.TermConditionsModule
      ),
  },
  {
    path: 'our-customer',
    loadChildren: () =>
      import('./our-customer/our-customer.module').then(
        (m) => m.OurCustomerModule
      ),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'drugs',
    loadChildren: () =>
      import('./drugs/drugs.module').then((m) => m.DrugsModule),
  },
  {
    path: 'drugs-detail',
    loadChildren: () =>
      import('./drugs-view/drugs-detail.module').then(
        (m) => m.DrugsDetailModule
      ),
  },
  {
    path: 'omim',
    loadChildren: () =>
      import('./omin/omin.module').then((m) => m.OminModule),
  },
  {
    path: 'omim-view',
    loadChildren: () =>
      import('./omin-view/omin-view.module').then((m) => m.OminViewModule),
  },
  {
    path: 'omim-search',
    loadChildren: () =>
      import('./omim-search/omim-search.module').then((m) => m.OmimSearchModule),
  },
  {
    path: 'statistics',
    loadChildren: () =>
      import('./statistics/statistics.module').then((m) => m.StatisticsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'phenotypic-series-titles',
    loadChildren: () =>
      import('./phenotypic-series/phenotypic-series.module').then(
        (m) => m.PhenotypicSeriesModule
      ),
  },
  {
    path: 'diseases-map',
    loadChildren: () =>
      import('./diseases-map/diseases-map.module').then(
        (m) => m.DiseasesMapModule
      ),
  },
  {
    path: 'disease-detail',
    loadChildren: () =>
      import('./disease-view/disease-view.module').then(
        (m) => m.DiseaseViewModule
      ),
  },
  {
    path: 'clinical-annotations',
    loadChildren: () =>
      import('./clinical-annotations/clinical-annotations.module').then(
        (m) => m.ClinicalAnnotationsModule
      ),
  },
  {
    path: 'snp-viewer',
    loadChildren: () =>
      import('./snpviewer/snpviewer.module').then((m) => m.SnpviewerModule),
  },
  {
    path: 'snp-details',
    loadChildren: () =>
      import('./snp-details/snp-details.module').then((m) => m.SnpDetailsModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('../app/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: '**',
    redirectTo: 'error/404',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
