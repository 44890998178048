import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'AIGR';
  loadingNumber = '1';

  constructor( public translate: TranslateService ) {translate.setDefaultLang('en'); }

  ngOnInit(): void {
    if (localStorage.getItem('loadingOption')) {
      this.loadingNumber = localStorage.getItem('loadingOption');
    } else {
      this.loadingNumber = '1';
    }
  }
}
