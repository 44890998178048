import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { DiseaseBannerComponent } from '../disease-view/disease-banner/disease-banner.component';
import { DiseaseViewModule } from '../disease-view/disease-view.module';
import { StatComponent } from './stat/stat.component';
import { ChartComponent } from './chart/chart.component';
import { ChartsModule } from 'ng2-charts';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, ScrollTopComponent, StatComponent, ChartComponent, PieChartComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    FlexLayoutModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ScrollTopComponent,
    StatComponent,
    ChartComponent,
    PieChartComponent,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class SharedModule {}
