import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  userUrl = environment.authUrl + 'auth/login';
  userLogout = environment.authUrl + 'auth/logout';
  urlSingUp = environment.authUrl + 'auth/register/';
  urlSendPing = environment.authUrl + 'auth/validate';
  // urlForgot = environment.authUrl + 'auth/forgot-password';
  // urlChangePass = environment.authUrl + 'auth/reset-password';
  urlForgot = environment.authUrl + 'auth/forgot';
  urlChangePass = environment.authUrl + 'auth/forgot';
  urlChangePassUserloged = environment.authUrl + 'change-pass';

  // -----------------------URL UPDATE PERSON------------------------ //
  urlProfile = environment.apiUrl + 'profile';

  constructor(private httpClient: HttpClient) {}

  login(user: string, password: string) {
    const base64EncodedPw = btoa(user + ':' + password);
    const data = {
      username: user,
      password: password,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + base64EncodedPw,
      }),
    };

    return this.httpClient.post<any>(this.userUrl, data, httpOptions);
  }

  logout(): Observable<any> {
    return this.httpClient.get(this.userLogout);
  }

  singUp(body): Observable<any> {
    return this.httpClient.post<any>(this.urlSingUp, body);
  }

  validatePing(body): Observable<any> {
    return this.httpClient.post<any>(this.urlSendPing, body);
  }

  passForgot(body): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('email', body.email);
    return this.httpClient.get<any>(this.urlForgot, { params: httpParams });
  }

  generateCode(body): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('email', body.email);
    return this.httpClient.get<any>(this.urlForgot, { params: httpParams });
  }

  changePass(body): Observable<any> {
    return this.httpClient.patch<any>(this.urlChangePass, body);
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem('user'));
  }

  isLoggedIn(): boolean {
    return this.getUser() !== null;
  }

  editProfile(data): Observable<any> {
    return this.httpClient.patch(this.urlProfile, data);
  }

  changePasswordUserLoged(data): Observable<any> {
    return this.httpClient.post(this.urlChangePassUserloged, data);
  }

  updateUserToken(resp: any) {
    const dataString = JSON.stringify(resp.user);
    console.log(resp);
    try {
      localStorage.setItem('user', dataString);
      localStorage.setItem('id', resp.id);
    } catch (e) {}
    // this.$loggedInUserUpdated.next(dataString);
  }
}

export const AUTH_PROVIDERS: Array<any> = [
  {
    provide: AuthenticationService,
    useClass: AuthenticationService,
  },
];
