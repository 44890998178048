<mat-card class="mat-elevation-z8">
  <mat-card-header>
        <div mat-card-avatar>
            <mat-icon class="icon-lg"
                      [ngStyle]="{'color': color}">
                      {{icon}}
            </mat-icon>
        </div>
        <mat-card-title [ngStyle]="{'color': color}"> {{count}} </mat-card-title>
        <mat-card-subtitle> {{label}} </mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-actions>
        <button mat-button
                color="primary"
                [ngStyle]="{'color': color}">
                {{footer}}
        </button>
        <button mat-button
                color="primary"
                *ngIf="footer2"
                [ngStyle]="{'color': color}">
                {{footer2}}
        </button>
  </mat-card-actions>
</mat-card>
