<header class="header_area">
  <div class="main_menu" id="mainNav">
    <nav class="navbar navbar-expand-lg navbar-scrolled fixed-top">
      <div class="container">
        <a class="logo" href="/about" style="color: steelblue; font-size: 24px">
          <img
            width="120px"
            src="../../../assets/images/AIGR - COLOR.png"
            alt=""
            style="opacity: 0.8; padding-right: 4px"
          />
        </a>

        <!--<div class="d-flex">
          <h1
            class="font-weight-bold mr-2 px-3"
            style="color: #25232c; background-color: #AEC926 ; opacity: 0.4;"
          >
            A
          </h1>
          <h1 style="color: lightgrey; opacity: 0.4;">IGR</h1>
        </div> -->

        <!-- Brand and toggle get grouped for better mobile display -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <!-- Collect the nav links, forms, and other content for toggling -->
        <div
          class="collapse navbar-collapse offset text-color-white"
          id="navbarSupportedContent"
        >
          <ul class="nav navbar-nav ml-auto">
            <li class="nav-item active">
              <a
                class="nav-link nav-link-color"
                routerLink="/"
                style="color: lightgrey"
                >{{ "Home" }}</a
              >
            </li>
            <li class="nav-item submenu dropdown">
              <a
                href="#"
                class="nav-link dropdown-toggle nav-link-color"
                style="color: lightgrey"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ "Company" }}</a
              >
              <ul
                class="dropdown-menu"
                style="background-color: #16151a; color: lightgrey"
              >
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/about"
                    style="color: lightgrey"
                    >About Us</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="/our-history"
                    style="color: lightgrey"
                    >Our History</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="/our-customer"
                    style="color: lightgrey"
                    >Our Customer</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="/term-conditions"
                    style="color: lightgrey"
                    >Terms and Conditions</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="/privacy-policy"
                    style="color: lightgrey"
                    >Privacy Policy</a
                  >
                </li>
              </ul>
            </li>
            <!-- GENOMIC MENU MOVED TO DASHBOARD-->
            <!--
            <li *ngIf="userLogged" class="nav-item submenu dropdown">
              <a
                href="#"
                class="nav-link dropdown-toggle nav-link-color"
                style="color: lightgrey"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ "Genomics" }}</a
              >
              <ul
                class="dropdown-menu"
                style="background-color: #16151a; color: lightgrey"
              >
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/diseases-map"
                    style="color: lightgrey"
                    >Diseases Map</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/omim"
                    style="color: lightgrey"
                    >Omim</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/omim-search"
                    style="color: lightgrey"
                    >OMIM Search</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/phenotypic-series-titles"
                    style="color: lightgrey"
                    >Phenotypic Series</a
                  >
                </li>
                <li class="nav-item"><a class="nav-link" href="#">Genomics Workspace</a></li>
                <li class="nav-item"><a class="nav-link" href="#">Profesional Services</a></li>
                <li class="nav-item"><a class="nav-link" href="#">Labs</a></li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/icd" style="color: lightgrey"
                    >ICD</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/icd-search"
                    style="color: lightgrey"
                    >ICD Search</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/clinical-annotations" style="color: lightgrey;">Clinical Annotations</a>
                </li>
              </ul>
            </li>
          -->
            <!--/GENOMIC MENU END-->
            <!--<li class="nav-item">
              <a class="nav-link nav-link-color" routerLink="/consult"
                ><b class="consult-border" style="color: lightgrey"
                  >Consult</b
                ></a
              >
            </li>
            <li *ngIf="userLogged" class="nav-item">
              <a class="nav-link nav-link-color" routerLink="/search"
                ><b class="consult-border" style="color: lightgrey"
                  >Search</b
                ></a
              >
            </li>-->
            <li class="nav-item">
              <a
                class="nav-link nav-link-color"
                routerLink="/faqs"
                style="color: lightgrey"
                >FAQs</a
              >
            </li>
            <li *ngIf="userLogged" class="nav-item">
              <a  class="nav-link nav-link-color"
                  routerLink="/admin"
                  style="color: lightgrey">
                  Admin
              </a>
            </li>
            <!--<li *ngIf="userLogged" class="nav-item">
              <a class="nav-link nav-link-color" routerLink="/statistics" style="color: lightgrey;"
                >Dashboard</a
              >
            </li>-->
            <li *ngIf="!userLogged" class="nav-item">
              <a
                class="nav-link nav-link-color"
                routerLink="/access/login"
                style="color: lightgrey"
                >{{ "Login" }}</a
              >
            </li>
            <li *ngIf="!userLogged" class="nav-item">
              <a
                class="nav-link nav-link-color"
                style="color: #aec926"
                routerLink="/access/register"
                >{{ "Register" }}</a
              >
            </li>

            <li *ngIf="userLogged" class="nav-item submenu dropdown">
              <a
                href="#"
                class="nav-link dropdown-toggle nav-link-color"
                style="color: #aec926"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ userLogged.username }}</a
              >
              <ul
                class="dropdown-menu"
                style="background-color: #16151a; color: lightgrey"
              >
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/admin"
                    style="color: lightgrey"
                    >Dashboard</a
                  >
                </li>
                <li *ngIf="isAdmin" class="nav-item">
                  <a
                    class="nav-link"
                    role="button"
                    style="color: lightgrey; cursor: pointer"
                    (click)="testAPI()"
                    >Data API Status</a
                  >
                </li>
                <li *ngIf="isAdmin" class="nav-item">
                  <a
                    class="nav-link"
                    role="button"
                    style="color: lightgrey; cursor: pointer"
                    (click)="testAuth()"
                    >Auth API Status</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/access/myprofile"
                    style="color: lightgrey"
                    >Account</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/access/password-change"
                    style="color: lightgrey"
                    >Password Change</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    (click)="Logout()"
                    style="color: lightgrey; cursor: pointer"
                    >Logout</a
                  >
                </li>
              </ul>
            </li>
            <!-- new menu multi-->
            <!-- /new menu multi end-->
          </ul>
        </div>
      </div>
    </nav>
  </div>
</header>
