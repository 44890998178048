import { Component,  Input, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.css']
})
export class StatComponent implements OnInit {

  @Input() bgClass: string;
  @Input() icon: string;
  @Input() count: string;
  @Input() label: string;
  @Input() color: string;
  @Input() data: number;
  @Input() footer: string;
  @Input() footer2: string;


  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  /*openDialog(): void {
    this.dialog.open(DialogDataComponent, {
      data: this.dataFilter,
      width: '90%',
      maxHeight: '90%'
    });
  }*/
}
