<mat-card class="mat-elevation-z8">
  <mat-card-header>
    <mat-card-title>{{title}}</mat-card-title>
    <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <canvas baseChart
        [datasets]="ChartData"
        [labels]="ChartLabels"
        [options]="ChartOptions"
        [plugins]="ChartPlugins"
        [colors]="ChartColors"
        [legend]="ChartLegend"
        [chartType]="ChartType">
    </canvas>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-footer>{{footer}}</mat-card-footer>
</mat-card>
