import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() footer: string;
  @Input() ChartData: Array<ChartData>;
  @Input() ChartLabels: Array<any>;
  @Input() ChartType: ChartType;

  ChartLegend = true;
  ChartPlugins = [];
  ChartOptions: any = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      'yAxes-0': { position: 'left' },
      'y-axis-1': {
        position: 'right',
        grid: { color: 'rgba(255,0,0,0.3)'},
        ticks: { color: 'red'}
      }
    }
  };
  ChartColors: Array<any> = [
    {
      backgroundColor: 'rgb(194,238,187,0.3)',
      borderColor: '#90be6d',
      pointBackgroundColor: 'rgb(194,238,187,0.3)',
      pointBorderColor: '#90be6d',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
    {
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
    {
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)',
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
export interface ChartData {
  data: Array<number>;
  label: string;
}
