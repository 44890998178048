<div class="container-fluid mt-5">
  <div class="card mx-5">
    <div class="row mb-4">
      <div class="col-md-4 col-sm-11 col-xs-11">
        <div class="footer-text pull-left">
          <div class="d-flex">
            <h1
              class="font-weight-bold mr-2 px-3"
              style="color: #25232c; background-color: #AEC926 "
            >
              L
            </h1>
            <h1 style="color: lightgrey">inks</h1>
          </div>
          <div class="social mt-2 mb-3">
            <i class="fa fa-facebook-official fa-lg"></i>
            <i class="fa fa-twitter fa-lg"></i>
            <i class="fa fa-linkedin-square fa-lg"></i>
          </div>
        </div>
      </div>

      <div class="col-md-2 col-sm-1 col-xs-1 mb-2"></div>

      <div class="col-md-2 col-sm-4 col-xs-4">
        <h5 class="heading">Services</h5>
        <ul>
          <li routerLink="/consult">Consulting </li>
        </ul>
      </div>

      <div class="col-md-2 col-sm-4 col-xs-4">
        <h5 class="heading">Products</h5>
        <ul class="card-text">
          <li>
             <a href="https://login.pgxsoftware.com/" target="_blank">PGx</a>
          </li>
        </ul>
      </div>

      <div class="col-md-2 col-sm-4 col-xs-4">
        <h5 class="heading" >Company</h5>
        <ul class="card-text">
          <li routerLink="/about">About Us</li>
          <li routerLink="/access/register">Join Us</li>
        </ul>
      </div>
    </div>

    <div class="divider mb-4"></div>

    <div class="row" style="font-size: 14px">
      <div class="col-md-6 col-sm-6 col-xs-6">
        <div class="pull-left">
          <p>
            <i class="fa fa-copyright"></i> {{ year }} A.I. Genomic Resources
          </p>
        </div>
      </div>

      <div class="col-md-6 col-sm-6 col-xs-6">
        <div class="pull-right mr-4 d-flex policy">
          <div routerLink="/term-conditions">Terms of Use  </div>
          <div></div>
          <div routerLink="/privacy-policy">  Privacy Policy</div>
        </div>
      </div>
    </div>
  </div>
</div>
