<ngx-spinner
  bdColor="rgba(0,0,0, 0.8)"
  type=""
  fullScreen="false"
  time
>
  <!--<img class="loading-main" src='../assets/images/gif/dna{{loadingNumber}}.gif' />-->
  <img class="loading-main" src='../assets/images/gif/dna.gif' />
  <p class="cargando" > Loading... </p>
</ngx-spinner>
<router-outlet></router-outlet>