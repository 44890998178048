import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class apisTestService {
  result: any;
  users: any;

  apiUrl = `${environment.apiUrl}`;
  authUrl = `${environment.authUrl}`;

  constructor(private httpClient: HttpClient) {}

  // check connection
  public testConnection(): boolean {
    return navigator.onLine;
  }

  // get api url
  public getApiUrl(): string {
    return `${this.apiUrl}PhenotypicSeries`;
  }

  // get auth url
  public getAuthUrl(): string {
    return `${this.authUrl}auth/user/`;
  }


}
